import React, { useState, useEffect } from 'react';
import {baseUrl, fetchApi} from "../stores/apiStore";

/**
 * ServerStatusIndicator Component
 *
 * This component polls the server (e.g., via a simple GET request to `/health` or `/ping`)
 * and updates an indicator color: green if successful, red if unsuccessful.
 */
/*
 TODO: add an option to control the displayed text from the constructor
    to make this usable for other features too
    (consider the same for poll interval, and font size)

 TODO: also there is a typo in the filename
*/

function ServerStatusIndicator({user, action}) {
  console.log(user);
  // State indicating whether the connection is alive
  const [isConnected, setIsConnected] = useState(true);

  // Optional: poll frequency in milliseconds
  const POLL_INTERVAL = 7000;



  async function fetchWithTimeout(url, timeout = 10000) {
    console.log(url);

    const controller = new AbortController();
    const signal = controller.signal;

    const timeoutId = setTimeout(() => {
        controller.abort(); // Abort the fetch
    }, timeout);
    let didErrorOccurred = false;

    try {
        const response = await fetchApi(url, user, {});
        return await response;
    }
    catch (error) {
        didErrorOccurred = true;
        if (error.name === 'AbortError') {
            console.error('Fetch aborted due to timeout.');
            setIsConnected(false);
        } else {
            setIsConnected(false);
        }
    }
    finally {
        clearTimeout(timeoutId); // Clear timeout to avoid memory leaks
        if (!didErrorOccurred) {
            setIsConnected(true);
            console.log('Server is connected');
        }
    }
};

  // Set up the polling when the component mounts
  useEffect(() => {
    // Immediately check once at component mount
    fetchWithTimeout(action);

    // Re-check periodically
    const intervalId = setInterval(() => {
      fetchWithTimeout(action);
    }, POLL_INTERVAL);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  // Choose a color based on the connection state
  const indicatorColor = isConnected ? 'green' : 'red';

  // Basic styles for the indicator circle (adjust as needed)
  const circleStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: indicatorColor,
    display: 'inline-block',
    marginRight: '8px',
    marginLeft: '8px',
    borderColor: 'black',
    borderWidth: '1px',
  };
  const textStyle = {
      fontSize: '15px',
      color: indicatorColor,
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={circleStyle} />
      <span style={textStyle}>{isConnected ? 'מחובר לשרת - כל שינוי שתבצעו יישמר בהצלחה ברגע שתצאו ממצב עריכה' : 'מנותק מהשרת! העתיקו את תשובתכם וטענו את העמוד מחדש'}</span>
    </div>
  );
}

export default ServerStatusIndicator;
