import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import ComponentSelector from "./ComponentSelector";
import "./HoverWrapper.css";

// A wrapper component that adds a hover effect to its children
const HoverWrapper = ({ hoverText, children}) => {
  // Function to inject dynamic styles into the document
  return (
    <div className="hover-wrapper">
      {children.map((child, index) => (
        <div key={index}>{ComponentSelector(child.component)}</div>
      ))}
      <span className="hover-text">{hoverText}</span>
    </div>
  );
};

export default HoverWrapper;
