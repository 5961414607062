import React from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom'

import {fetchApi, fetchPage} from "../stores/apiStore";
import {Container, Modal} from "react-bootstrap";
import {container, item} from "./MultiComponentRender.css";
import {pageContext} from "../stores/pageContext";
import Loader from "../components/LoadingAlert";
import ComponentSelector from "../components/ComponentSelector";
import HebrewLabel from "../components/HebrewLabel";
import {COLORS, FONTS, HTTP_RESPONSE} from "../constants";
import instructionStore from "../stores/instructionStore";

/**
 * Singleton that handles drawing TalpiX components.
 */


class MultiComponentRender extends React.Component {
    // sets state.data to props, if props is null sets an empty list
    // PARAM props = (login, pagename)
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            user: props.login,
            serverResponse: {responseStatus: 0, responseMessage: ''},
            isLoading: false
        };
        this.cancelTokenSource = axios.CancelToken.source();

        this.doAction = this.doAction.bind(this);
    }

    componentDidMount() {
        // when the page first reloads, call the refreshPage function
        this.cancelTokenSource = axios.CancelToken.source();
        this.refreshPage()
    }

    componentDidUpdate(prevProps) {
        //Called when any component changes
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            if (this.props.login.access !== prevProps.login.access)
                return
            //If the props are changed
            this.setState({data: []});
            this.cancelTokenSource.cancel();
            this.cancelTokenSource = axios.CancelToken.source();
            console.log("props", prevProps, this.props);
            this.refreshPage();
        }
    }

    //if there is an error print it
    defaultOnError = (error) => {
        const {serverResponse} = this.state;
        serverResponse.responseStatus = error.response && error.response.status
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            serverResponse.responseMessage = typeof errorData === 'object' ? errorData.error : errorData
        } else if (error.isAxiosError) {
            serverResponse.responseStatus = HTTP_RESPONSE.CONNECTION_ERROR
        }
        this.setState({serverResponse, isLoading: false});
        console.error("Got an error", error)
        console.log(Object.entries(error), error.response)
    }

    async refreshPage() {
        //get fetch function from props
        let pageName = this.props.pageName;
        let params = this.props.params;
        const user = this.props.login;
        const isDev = this.props.isDev;

        if (!pageName) //if null
            return;

        await this.updateData(fetchPage(pageName, user, params, this.cancelTokenSource, isDev), true)
    }

    async doAction(actionUrl, actionData, returnResponse = false) {
        // when action happens, sent the action data to the server with post request
        if (!actionUrl)
            return

        const user = this.props.login;
        const isDev = this.props.isDev;

        //fetch the json from the server and store it in result
        const fetchFunction = fetchApi(actionUrl, user, actionData, this.cancelTokenSource, isDev);
        if (returnResponse) {
            return fetchFunction;
        }
        await this.updateData(fetchFunction, false);
    }

    async updateData(fetchFunction, enforceNewData) {
        // when action happens, sent the instruction data to the server with post request
        if (!fetchFunction)
            return

        const {onError = this.defaultOnError} = this.props;
        const {isLoading} = this.state; //the data we fetched with {refreshPage}

        if (enforceNewData) {
            instructionStore.data = [];
        }

        this.setState({isLoading: enforceNewData ? isLoading : null});
        setTimeout(() => {
            if (this.state.isLoading === null) {
                this.setState({isLoading: true});
            }
        }, 1500)

        //fetch the jason from the server and store it in result
        fetchFunction.then(
            result => {
                const {serverResponse} = this.state;
                serverResponse.responseStatus = result.status; // update status from server
                this.setState({serverResponse})

                result = result.data;
                if (result.detail != null || result.instructions === undefined)
                    return;

                instructionStore.handleInstruction(result.instructions, this.props)

                // sets the data in the state to our changed data
                this.setState({data: instructionStore.data, isLoading: false});
            },
            onError // do something if an error happens
        )
    }

    render() {
        const {data, serverResponse: {responseStatus, responseMessage}, isLoading, user} = this.state; // get the data from the state

        const BAD_REQUEST_MESSAGE = `נסו לטעון מחדש את העמוד או להתחבר לחשבון של תלפיות`;
        const SERVER_ERROR_MESSAGE = 'השרת נתקל בשגיאה';
        const CONNECTION_ERROR_MESSAGE = `בעיית חיבור לשרת` + ' ' + (responseMessage || '');

        const onLoadingComponent = <Modal
            size="xs"
            show={isLoading}
            centered
            onHide={() => {
                this.setState({isLoading: false});
            }}
        >
            <Container style={{padding: 0}}>
                {Loader}
            </Container>
        </Modal>;

        let errorMessage = '';
        if (HTTP_RESPONSE.equal(responseStatus, HTTP_RESPONSE.BAD_REQUEST)) {
            errorMessage = responseMessage || BAD_REQUEST_MESSAGE
        } else if (HTTP_RESPONSE.equal(responseStatus, HTTP_RESPONSE.SERVER_ERROR)) {
            errorMessage = SERVER_ERROR_MESSAGE
        } else if (HTTP_RESPONSE.equal(responseStatus, HTTP_RESPONSE.CONNECTION_ERROR)) {
            errorMessage = CONNECTION_ERROR_MESSAGE
        }

        // this function adds the access token to the ServerStatusIndicator to give its functionallity
        const f = (item) => {
            /* TODO: it's a weird dependency to have here (the exact name of the specific component)
                would it be better to add the user field to all items without children?
                (the actual parameters are fed to the component by the ComponentSelector anyways)
            */
            if (item.type == "ServerStatusIndicator")
                item.user = user;
            if (item.children)
                item.children.forEach(child => { f(child.component) });
        }
        
        data.forEach(item => { f(item) });
        const isDataNotEmpty = data && data.length > 0;
        const renderedData = data.map((item) => {
            return <div className="item">
                <span className="sr-only">{item.id}</span>
                {ComponentSelector({...item})}
            </div>
        });
        const onEmptyData = <div className={item}>
            <HebrewLabel font={FONTS.RUBIK} color={COLORS.ALERT} size="2rem">
                {errorMessage}
            </HebrewLabel>
            {/*if the data is empty display a loader*/}
            {errorMessage === '' ? Loader : null}
        </div>;

        return (
            <pageContext.Provider value={{onEvent: this.doAction}}>
                <Container className={container}>
                    {isDataNotEmpty ? renderedData : onEmptyData}
                    {onLoadingComponent}
                </Container>
            </pageContext.Provider>
        );
    }
}

export default withRouter(MultiComponentRender);