import React, {useEffect, useRef, useState} from "react";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import { stateFromHTML } from "draft-js-import-html";
import HebrewLabel from "./HebrewLabel";
import { FONTS } from "../constants";
import { Col } from "react-bootstrap";
import "./richTextEditor2.css";
import ServerStatusIndicator from "./ServerStatutsIndicator";


const MILLISECONDS_IN_MINUTES = 60000;

const defaultOnSave = (data) => {
    console.log("Saving data: ", data);
};

const RichTextEditor2 = ({
    initial_state,
    width = "70vw",
    height = "40vh",
    onSave = defaultOnSave,
    last_update = null,
}) => {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(stateFromHTML(initial_state))
    );
    const [minutesSinceLastUpdate, setMinutesSinceLastUpdate] = useState(0);
    const [htmlData, setHtmlData] = useState(initial_state || "");
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (last_update) {
                const timedelta = new Date().getTime() - new Date(last_update).getTime();
                const minutes = Math.floor(timedelta / MILLISECONDS_IN_MINUTES);
                setMinutesSinceLastUpdate(minutes);
            }
        }, MILLISECONDS_IN_MINUTES);
        return () => clearInterval(interval);
    }, [last_update]);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setHtmlData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const handleFocus = () =>
    {
        setIsFocused(true);
        setEditorState(EditorState.moveFocusToEnd(editorState));
    }
    const handleBlur = () => {
        setIsFocused(false);
        onSave({ data: htmlData });
    };

    const toolbarConfig = {
    options: ["inline", "textAlign", "list"], // Include fontSize and fontFamily here
    inline: {
        options: ["bold", "italic", "underline"],
    },
    list: {
        options: ["unordered"],
    },
    textAlign: {
        options: ["left", "center", "right", "justify"],
    },


};

    return (
        <Col>
            <div
                style={{
                    width: width,
                    height: "100%",
                    justifyContent: "center",
                    display: "flex",
                }}
                dir="ltr"
            >
                {isFocused ? (
                    <Editor
                        editorState={editorState}
                        toolbarClassName="custom-toolbar"
                        wrapperClassName="custom-editor-wrapper"
                        editorClassName="custom-editor"
                        onEditorStateChange={onEditorStateChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={{ width: width, height: height }}
                        toolbar={toolbarConfig}
                    />
                ) : (
                    <div
                    className="display-only-mode"
                    onClick={handleFocus}
                    style={{
                        color: htmlData.trim() ? "#000" : "#aaa", // Lighter color for placeholder
                    }}
                >
                    {htmlData.trim() ? (
                        <span dangerouslySetInnerHTML={{ __html: htmlData }}></span>
                    ) : (
                        <span>הקלד/י את תשובתך כאן...</span> // Placeholder text
                    )}
                </div>

                )}
                <style type="text/css">
                    {`
                        .rdw-editor-main {
                            background-color: #f7f7f9;
                            font: David;
                        }
                        .rdw-editor-wrapper {
                            height: ${height};
                            width: ${width};
                            margin-bottom: 10vh;
                        }
                        .rdw-editor-toolbar {
                            color: #222;
                        }
                        .DraftEditor-root {
                            font-size: 14px;
                        }
                    `}
                </style>
            </div>
        </Col>
    );
};

export default RichTextEditor2;